import { FastJsonList } from '@legacy/@core/type/Common'
import dayjs from 'dayjs'
import { JobType } from 'type/Common'
import { CandidatesProfileSerializerServer } from 'type/server/consultants/candidates.type'
import { analyzeObjectProxy } from 'utilities/AnalizeApi'
import { KanbanTableItem } from 'wrapper/consultants/candidates/hooks/useTableKanbanPaginationUrlQuery'
export type CandidateViewItem = KanbanTableItem & {
  createdAt?: string
  stageName?: string
  status?: number
  lastActivity?: string
  personalFullName?: string
  personalEmail?: string
  personalPhoneNumber?: number
  personalID?: number
  userId?: number
  jobID?: number
  jobTitle?: string
  clientShow?: any
  clientName?: string
  clientEmail?: string
  clientCompanyID?: number
  clientLogo?: string
  ownerShow?: any
  ownerFullName?: string
  ownerPhotoUrl?: string
  offer?: any
  placement?: any
  photoUrl?: string
  updatedAt?: string
  source?: number
  profileId?: number
  location?: string
  yoe?: number
  skills?: any
  updated_at?: string
  owner_id?: number
  last_cv_sent?: string
  id?: number
  stage_id?: number
  stage_index?: number
  reject_reason?: string
  job_status?: string
  stage_type_id?: number
  expected_salary_cents?: number
  salary_currency: string
  new?: boolean
  jobPICIds?: any
  jobStage?: string
  job?: JobType
  position?: number
  matched?: boolean
  active?: boolean
  last_activity: dayjs.Dayjs
  placement_id?: number
  reprocess_applicant_id?: number | string
  ams?: any
  sourceByPhotoURL?: string
  sourceByFullName?: string
  sourceById?: number | string
  confidential?: boolean
  timeLastInterview?: string
  evaluationScoreInterview?: number
  interViewId?: number
  offerId?: string | number
  cancelReason?: string
  placementStatus?: string
  profile_status?: number | string
  salary_offer: number
  profile_cv_file?: string
  profile_cv_name?: string
  placementState?: string
  placementStartDate?: string
  placementWarrantyEndData?: string
  placementPaymentTerms?: number
  placementInvoicedDate?: string
  placementPaymentTerm?: string
  job_address?: string
  replacementForId?: number
}

const mapping = ({ id, attributes }): { id; attributes: CandidateViewItem } => {
  return {
    id,
    attributes: {
      columnIndex: attributes?.job_stage?.data.attributes.index,
      createdAt: attributes?.created_at,
      stageName: attributes?.job_stage?.data.attributes.stage_label,
      status: attributes?.status,
      lastActivity: attributes?.last_activity,
      personalFullName: attributes?.profile?.data?.attributes?.full_name || '-',
      personalEmail: attributes?.profile?.data?.attributes?.email || '-',
      personalPhoneNumber: attributes?.profile?.data?.attributes?.phone_number || '-',
      personalID: attributes?.profile?.data?.attributes?.id || '',
      userId: attributes?.profile?.data?.attributes?.user_id,
      jobID: attributes?.job?.data?.attributes?.id,
      jobTitle: attributes?.job?.data?.attributes?.title,
      clientShow: !!attributes?.client?.data,
      clientName: attributes?.client?.data?.attributes?.name,
      clientEmail: attributes?.client?.data?.attributes?.email,
      clientCompanyID: attributes?.client?.data?.attributes?.company_id,
      clientLogo: attributes?.job?.data?.attributes?.client_logo_url,
      ownerShow: !!attributes?.owner?.data,
      ownerFullName: attributes?.owner?.data?.attributes?.full_name,
      ownerPhotoUrl: attributes?.owner?.data?.attributes?.photo_url,
      offer: attributes?.offer,
      placement: attributes?.placement,
      photoUrl: attributes?.profile?.data?.attributes?.photo_url || null,
      updatedAt: attributes.updated_at,
      source: attributes?.profile?.data?.attributes?.source,
      profileId: attributes?.profile?.data?.attributes?.id,
      location: attributes?.location?.data?.attributes.state,
      yoe: attributes.yoe,
      skills: attributes?.skills?.data?.map((item) => item.attributes),
      updated_at: attributes.updated_at,
      owner_id: attributes.owner?.data?.attributes.id,
      last_cv_sent: attributes.last_cv_sent,
      id: attributes?.id,
      stage_id: attributes?.job_stage?.data.attributes.id,
      stage_index: attributes?.job_stage?.data.attributes.index,
      reject_reason: attributes?.reject_reason,
      job_status: attributes?.job_status,
      stage_type_id: attributes?.job_stage?.data.attributes.stage_type_id,
      expected_salary_cents: attributes?.profile?.data?.attributes?.expected_salary_cents,
      salary_currency: attributes?.profile?.data?.attributes?.salary_currency,
      new: attributes?.new,
      jobPICIds: attributes?.job?.data?.attributes?.job_pic?.data?.map((item) => item.attributes.id),
      jobStage: attributes?.job_stage,
      job: attributes.job,
      position: attributes.position,
      matched: attributes.matched,
      last_activity: dayjs(attributes.last_activity),
      placement_id: attributes.placement_id,
      reprocess_applicant_id: attributes?.reprocess_applicant_id,
      sourceById: attributes?.source_by?.data?.attributes?.id,
      sourceByFullName: attributes?.source_by?.data?.attributes?.full_name,
      sourceByPhotoURL: attributes?.source_by?.data?.attributes?.photo_url,
      confidential: attributes?.confidential_job,
      ams: attributes?.ams?.data || [],
      timeLastInterview: attributes?.last_interview?.date_time,
      evaluationScoreInterview: attributes?.last_interview?.evaluation_score,
      interViewId: attributes?.last_interview?.id,
      offerId: attributes?.offer?.data?.attributes?.id,
      cancelReason: attributes?.last_interview?.cancel_reason,
      placementStatus: attributes?.deal_information?.status,
      placementState: attributes?.deal_information?.state,
      placementStartDate: attributes?.deal_information?.start_date,
      placementWarrantyEndData: attributes?.deal_information?.warranty_end_date,
      profile_status: attributes?.profile?.data?.attributes?.profile_status,
      salary_offer: attributes?.offer?.data?.attributes?.salary,
      profile_cv_file: attributes?.profile_cv?.data?.attributes?.file,
      profile_cv_name: attributes?.profile_cv?.data?.attributes?.name,
      placementPaymentTerms: attributes?.deal_information?.payment_term,
      replacementForId: attributes?.deal_information?.replacement_for_id
    }
  }
}

export const transformListCandidates = ({
  response
}: {
  response: FastJsonList<CandidatesProfileSerializerServer>
}): { totalCount: number; mapping: FastJsonList<CandidateViewItem> } => {
  return {
    mapping: {
      data: (response?.data || []).map((record) => {
        const { attributes, id } = record
        return mapping({ id, attributes })
      }),
      meta: response?.meta
    },
    totalCount: response?.meta?.count || 0
  }
}

const mappingJobPipeline = ({ id, attributes }) => {
  return {
    id,
    attributes: {
      createdAt: attributes?.created_at,
      stageName: attributes?.stage_name,
      status: attributes?.status,
      lastActivity: attributes?.last_activity,
      personalFullName: attributes?.profile?.data?.attributes?.full_name || '-',
      personalEmail: attributes?.profile?.data?.attributes?.email || '-',
      personalPhoneNumber: attributes?.profile?.data?.attributes?.phone_number || '-',
      profile_status: attributes?.profile?.data?.attributes?.profile_status,
      personalID: attributes?.profile?.data?.attributes?.id || '',
      userId: attributes?.profile?.data?.attributes?.user_id,
      jobID: attributes?.job?.data?.attributes?.id,
      jobTitle: attributes?.job?.data?.attributes?.title,
      ownerShow: !!attributes?.owner?.data,
      ownerFullName: attributes?.owner?.data?.attributes?.full_name,
      ownerPhotoUrl: attributes?.owner?.data?.attributes?.photo_url,
      offer: attributes?.offer,
      placement: attributes?.placement,
      photoUrl: attributes?.profile?.data?.attributes?.photo_url || null,
      updatedAt: attributes.updated_at,
      source: attributes?.sourced,
      profileId: attributes?.profile?.data?.attributes?.id,
      location: attributes?.location?.data?.attributes.state,
      yoe: attributes.yoe,
      skills: attributes?.skills?.data.map((item) => item.attributes),
      updated_at: attributes.updated_at,
      owner_id: attributes.owner?.data?.attributes.id,
      last_cv_sent: attributes.last_cv_sent,
      id: attributes?.id,
      stage_id: attributes?.job_stage_id,
      reject_reason: attributes?.reject_reason,
      job_status: attributes?.job_status,
      stage_type_id: attributes?.stage_type_id,
      expected_salary_cents: attributes?.expected_salary_cents,
      new: attributes?.new,
      jobPICIds: attributes?.job_pic?.data.map((item) => item.attributes.id),
      jobStage: attributes?.job_stage,
      job: attributes.job,
      position: attributes.position,
      matched: attributes.matched,
      job_stage_index: attributes.job_stage_index,
      job_stage_id: attributes.job_stage_id,
      last_activity: dayjs(attributes.last_activity),
      stage_index: attributes?.stage_index,
      clientEmail: attributes?.email_client,
      reprocess_applicant_id: attributes?.reprocess_applicant_id,
      sourceById: attributes?.source_by?.data?.attributes?.id,
      sourceByFullName: attributes?.source_by?.data?.attributes?.full_name,
      sourceByPhotoURL: attributes?.source_by?.data?.attributes?.photo_url,
      confidential: attributes?.confidential_job,
      ams: attributes?.ams?.data || [],
      timeLastInterview: attributes?.last_interview?.date_time,
      evaluationScoreInterview: attributes?.last_interview?.evaluation_score,
      interViewId: attributes?.last_interview?.id,
      offerId: attributes?.offer?.data?.attributes?.id,
      cancelReason: attributes?.last_interview?.cancel_reason,
      placementStatus: attributes?.placement?.data?.attributes.status,
      placement_id: attributes?.placement?.data?.attributes?.id,
      salary_offer: attributes?.offer?.data?.attributes?.salary,
      profile_cv_file: attributes?.profile_cv?.data?.attributes?.file,
      profile_cv_name: attributes?.profile_cv?.data?.attributes?.name,
      placementState: attributes?.placement?.data?.attributes?.state,
      placementStartDate: attributes?.placement?.data?.attributes?.start_date,
      placementWarrantyEndData: attributes?.placement?.data?.attributes?.warranty_end_date,
      placementPaymentTerms: attributes?.placement?.data?.attributes?.payment_term,
      placementInvoicedDate: attributes?.placement?.data?.attributes?.invoiced_date,
      placementId: attributes?.placement?.data?.id,
      replacementForId: attributes?.placement?.data?.attributes?.replacement_for_id
    }
  }
}

export const transformListCandidatesJobPipeline = ({ response }: { response }) => {
  return {
    mapping: {
      data: (response?.data || []).map((record) => {
        const { attributes, id } = record
        return mappingJobPipeline({ id, attributes })
      })
    }
  }
}
