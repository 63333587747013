import React, { ReactElement, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { DataProvider } from '../store/store'
import { LayoutProvider } from '../layout/DefaultLayout'
import createI18n from '../locales/i18n'
import { ServerVarStoreType } from '@legacy/@core/utilities/CollectServerVariableContent'
import { FreeCNextPage } from '../type/Common'
import { useNProgress } from '../hooks/NProgress'
import { getIsServer } from '../utilities/common'
import { getCookie } from '../utilities/Cookies'
import FreecProvider, { ServerBunble, ServerService } from '@legacy/@core/components/FreecProvider'
import { DEFAULT_LOCALE } from '@legacy/@core/utilities/Constant'
import { COOKIES_NAME_LANGUAGE } from '@legacy/@core/utilities/Cookies'
import { useRouter } from 'next/router'
// core styles shared by all of react-notion-x (required)
import 'react-notion-x/src/styles.css'
// used for code syntax highlighting (optional)
import 'prismjs/themes/prism-tomorrow.css'
// used for rendering equations (optional)
import 'katex/dist/katex.min.css'
interface FreecAppProps extends AppProps {
  Component: FreeCNextPage
  serverVar: ServerVarStoreType
  cookieLanguage?: string
  serverGetCookie?: (key: string) => string
}

function FreecApp({
  Component,
  pageProps,
  serverVar,
  serverGetCookie,
  serverBundle,
  serverService
}: FreecAppProps & { serverService: ServerService; serverBundle: ServerBunble }): ReactElement {
  const realLocale = (getIsServer() ? DEFAULT_LOCALE : getCookie(COOKIES_NAME_LANGUAGE)) || DEFAULT_LOCALE
  createI18n(realLocale)
  useNProgress()
  const router = useRouter()
  const [clientRender, setClientRender] = useState(false)
  useEffect(() => {
    if (router.isReady) setClientRender(true)
  }, [router.isReady])

  return (
    <FreecProvider serverBundle={serverBundle} serverService={serverService}>
      <DataProvider serverVar={serverVar} getCookie={getIsServer() ? serverGetCookie : getCookie}>
        <LayoutProvider>{clientRender ? <Component {...pageProps} /> : <div> </div>}</LayoutProvider>
      </DataProvider>
    </FreecProvider>
  )
}
// FreecApp.getInitialProps = initAppPropsWrapper([
//   readAuthenticateTokenMiddleWare,
//   attachAthenticateMetaDataMiddleWare,
//   securityRedirectMiddleWare
// ])(async (appContext, mContext) => {
//   const req = appContext.ctx.req as NextRequestType
//   const appProps = await App.getInitialProps(appContext)
//   const locale = mContext.cookies.getCookie(COOKIES_NAME_LANGUAGE) || DEFAULT_LOCALE
//   const serverBundle = getIsServer() && { isMobile: app_IsMobile(req) }
//   const serverService: ServerService = getIsServer() && {
//     getCookie: mContext.cookies.getCookie,
//     originalUrl: req.url,
//     isMobile: !!app_IsMobile(req),
//     agentDetect: {
//       isMobile: app_IsMobile(req),
//       os: app_GetOS(req)
//     }
//   }
//   if (appContext.ctx.err) {
//     Sentry.captureException(appContext.ctx.err)
//   }
//   return { ...appProps, locale, serverBundle, serverService }
// })

export default FreecApp
