import { createAPIClient } from '@legacy/@core/utilities/APIClient/CreateApiClient'
import { NEXT_PUBLIC_API_END_POINT, NEXT_PUBLIC_ENABLE_API_PROXY } from './env'
import Router from 'next/router'
import { LINK_URL_LOGIN } from './LinkURL'
import { safe_clearPrivateData } from 'datasource/select/utils'
import { emitApiForbidden } from 'hooks/event/ApiForbiddendEvent'

export default createAPIClient(
  NEXT_PUBLIC_ENABLE_API_PROXY ? '/' : NEXT_PUBLIC_API_END_POINT,
  () => {
    safe_clearPrivateData()
    Router.push(LINK_URL_LOGIN)
  },
  (args) => {
    emitApiForbidden(args)
    // safe_clearPrivateData()
    // Router.push(LINK_URL_LOGIN)
  }
)
