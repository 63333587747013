import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { ClientRequestConfigType, ClientType } from '.'
import { R_HEADER_X_REFERRAL_ID, X_AUTH_TOKEN } from '../Client'
import {
  COOKIES_NAME_REFERRAL_ID,
  COOKIES_NAME_UTM_CAMPAIGN,
  COOKIES_NAME_UTM_MEDIUM,
  COOKIES_NAME_UTM_SOURCE,
  deleteCookie,
  getCookie
} from '../Cookies'
const createDurationLog = (url, promise) => {
  // const fetchEvent = `api fetch ${url}`
  // eslint-disable-next-line
  // console.time(fetchEvent)
  return promise.then((value) => {
    // eslint-disable-next-line
    // console.timeEnd(fetchEvent)
    return value
  })
}
const createRequestMethod = <T>(
  axiosInstance: AxiosInstance,
  onUnauthorization?: () => void,
  onForbidden?: (args: { apiPath: string }) => void
) => (apiPath: string, requestConfig: ClientRequestConfigType): Promise<AxiosResponse<T>> => {
  const headers = { ...requestConfig.headers }
  // headers['Accept-Language'] = 'en'
  const utmPrams = {}
  const getToken = localStorage.getItem('AUTHENTICATION_TOKEN')
  if (!requestConfig.ignoreAuthentication && getToken) {
    headers.Authorization = `${getToken}`
  }
  if (getCookie(COOKIES_NAME_REFERRAL_ID)) {
    headers[R_HEADER_X_REFERRAL_ID] = getCookie(COOKIES_NAME_REFERRAL_ID)
  }
  if (getCookie(COOKIES_NAME_UTM_SOURCE)) {
    headers[COOKIES_NAME_UTM_SOURCE] = getCookie(COOKIES_NAME_UTM_SOURCE)
    utmPrams[COOKIES_NAME_UTM_SOURCE] = getCookie(COOKIES_NAME_UTM_SOURCE)
  }
  if (getCookie(COOKIES_NAME_UTM_CAMPAIGN)) {
    headers[COOKIES_NAME_UTM_CAMPAIGN] = getCookie(COOKIES_NAME_UTM_CAMPAIGN)
    utmPrams[COOKIES_NAME_UTM_CAMPAIGN] = getCookie(COOKIES_NAME_UTM_CAMPAIGN)
  }
  if (getCookie(COOKIES_NAME_UTM_MEDIUM)) {
    headers[COOKIES_NAME_UTM_MEDIUM] = getCookie(COOKIES_NAME_UTM_MEDIUM)
    utmPrams[COOKIES_NAME_UTM_MEDIUM] = getCookie(COOKIES_NAME_UTM_MEDIUM)
  }
  return createDurationLog(
    apiPath,
    axiosInstance
      .request({
        url: apiPath,
        ...requestConfig,
        params: { ...(requestConfig.sendUTM ? utmPrams : {}), ...requestConfig.params },
        headers
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        if (!requestConfig.preventRedirect && error.response && error.response.status === 401) {
          if (location.pathname.indexOf('/login') !== 0) {
            deleteCookie(X_AUTH_TOKEN)
            localStorage.setItem('AUTHENTICATION_TOKEN', '')
            onUnauthorization && onUnauthorization()
            // window.location.href = `/login`
          }
        }

        if (
          !requestConfig.preventRedirect &&
          error.response &&
          (error.response.status === 403 || (error.response.status === 422 && error.response.data.messages === 'user_not_authorized'))
        ) {
          // deleteCookie(X_AUTH_TOKEN)
          // localStorage.setItem('AUTHENTICATION_TOKEN', '')
          onForbidden && onForbidden({ apiPath })
          // window.location.href = `/login`
        }
        if (!error.response || (error.response && error.response.status === 500)) {
          // toast.error('A Request not working properly. Please contact to admin system for more detail')
        }
        throw error
      })
  )
}
export function createAPIClient(endpoint, onUnauthorization, onForbidden): ClientType {
  const instance = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: endpoint,
    // timeout: "",
    withCredentials: true
  })
  return {
    get: <T>(path: string, options: ClientRequestConfigType) =>
      createRequestMethod<T>(
        instance,
        onUnauthorization,
        onForbidden
      )(path, {
        method: 'get',
        ...options
      }),
    post: <T>(path: string, options: ClientRequestConfigType) =>
      createRequestMethod<T>(
        instance,
        onUnauthorization,
        onForbidden
      )(path, {
        method: 'post',
        ...options
      }),
    put: <T>(path: string, options: ClientRequestConfigType) =>
      createRequestMethod<T>(
        instance,
        onUnauthorization,
        onForbidden
      )(path, {
        method: 'put',
        ...options
      }),
    patch: <T>(path: string, options: ClientRequestConfigType) =>
      createRequestMethod<T>(
        instance,
        onUnauthorization,
        onForbidden
      )(path, {
        method: 'patch',
        ...options
      }),
    delete: <T>(path: string, options: ClientRequestConfigType) =>
      createRequestMethod<T>(
        instance,
        onUnauthorization,
        onForbidden
      )(path, {
        method: 'delete',
        ...options
      })
  }
}
